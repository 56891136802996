import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/home-style.css";
import logoImg from "../../assets/home-img/logo.png";
import checkImg from "../../assets/home-img/congratulations-check-mark.png";
import * as yup from "yup";
import HomeSidebar from "./home-sidebar";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  checkEmail,
  registerUser,
  verificationCodeAction,
  verifyEmailAction,
} from "../../store/actions/authAction";
import usePlacesAutocomplete, {
  getDetails,
  getLatLng,
} from "use-places-autocomplete";

const storeTypes = [
  "Restaurant",
  "Cafe",
  "Takeaway Restaurant",
  "Sushi Restaurant",
  "Supermarket",
  "Other",
];

const schema = yup.object({
  name: yup.string().trim().required("name is Required"),
  email: yup.string().trim().required("Email is Required"),
  password: yup.string().trim().required("password is Required"),
});

const SingupForm = ({ history }) => {
  const [selectedStoreType, setSelectedStoreType] = useState("");
  const [showSidebar, setShowSidebar] = useState(false);
  const [view, setView] = useState("store");
  const [msg, setMsg] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [isLoading, setLoading] = useState(false);
  const [address, setAddress] = useState({});
  const [code, setCode] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    setShowSidebar(false);
  }, [window.location.href]);

  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const registerHandler = async (data) => {
    if (!address?.address) {
      setMsg("Please add location");
      setTimeout(() => {
        setMsg("");
      }, 3000);
      return;
    }

    if (!address?.locationId) {
      setMsg("Please select location from the drop down");
      setTimeout(() => {
        setMsg("");
      }, 3000);
      return;
    }

    setMsg("");

    setLoading(true);

    const value = await dispatch(checkEmail({ email: data.email }));
    setLoading(false);

    if (value?.status === "fail") {
      setMsg(value?.error);

      setTimeout(() => {
        setMsg("");
      }, 5000);
      return;
    }

    await dispatch(verifyEmailAction({ email: data.email }));
    setView("verification");
  };

  const {
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: { componentRestrictions: { country: "us" } },
    debounce: 300,
  });

  const renderSuggestions = () =>
    data.map((suggestion, index) => {
      const {
        id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div
          style={{ padding: 5 }}
          key={index}>
          <p
            style={{
              color: "#333333",
              marginBottom: 0,
              cursor: "pointer",
              textAlign: "left",
            }}
            key={"mapsuggestion" + index}
            onClick={handleSelect(suggestion)}>
            <strong>{main_text}</strong> <small>{secondary_text}</small>
          </p>
        </div>
      );
    });

  const handleSelect = (data) => async () => {
    const placeDetails = await getDetails({ placeId: data.place_id });

    const { address_components } = placeDetails;

    const { lat, lng } = await getLatLng(placeDetails);

    const street_number = address_components.find((component) =>
      component.types.includes("street_number"),
    )?.long_name;

    const address = address_components.find((component) =>
      component.types.includes("route"),
    )?.long_name;

    const city = address_components.find((component) =>
      (component.types.includes("locality") || component.types.includes("sublocality")),
    )?.long_name;

    const state = address_components.find((component) =>
      component.types.includes("administrative_area_level_1"),
    )?.short_name;

    const zip = address_components.find((component) =>
      component.types.includes("postal_code"),
    )?.long_name;

    setAddress({
      locationId: data.place_id,
      location: data.description,
      address: street_number ? (street_number  + " " + address) : address,
      city,
      state,
      zip,
      latitude: lat,
      longitude: lng,
    });

    clearSuggestions();
  };

  const storeNextHandler = () => {
    if (!selectedStoreType) {
      setMsg("Please select store");

      setTimeout(() => {
        setMsg("");
      }, 3000);
      return;
    }

    setMsg("");
    setView("form");
  };

  const sendCodeHandler = async () => {
    if (!code) {
      setMsg("Please add code");

      setTimeout(() => {
        setMsg("");
      }, 3000);

      return;
    }

    setMsg("");
    setLoading(true);
    const res = await dispatch(
      verificationCodeAction({ email: getValues("email"), code }),
    );

    if (res?.status === "fail") {
      setMsg("Invalid Code");

      setTimeout(() => {
        setMsg("");
      }, 3000);

      setLoading(false);
      return;
    }

    if (res?.status === "success") {
      setView("success");
      const data = getValues();

      const newUser = await dispatch(
        registerUser({ ...data, ...address, type: 2,
          language: 'en',
          phone: '',
          profilePhoto: '',
          taxId: '',
          website: '',
          zip: ''        
         }),
      );

      if (!newUser?.isApproved) {
        setView("success");
      }
    }

    setLoading(false);
  };

  return (
    <div>
      <HomeSidebar
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
        history={history}
      />
      <header className="header">
        <div className="logo">
          <a role="button">
            <img
              src={logoImg}
              alt="Waste not Want not Logo"
            />
          </a>
        </div>
        <nav className="nav">
          <ul>
            <li>
              <a
                role="button"
                onClick={() => {
                  history.push("/");
                }}>
                Home
              </a>
            </li>
            <li>
              <a
                role="button"
                onClick={() => {
                  history.push("/#about-us");
                }}>
                About us
              </a>
            </li>
            <li>
              <a
                role="button"
                onClick={() => {
                  history.push("/wnwn/support");
                }}>
                Support
              </a>
            </li>
            {/* <li>
              <a href="#">Help</a>
            </li> */}
          </ul>
        </nav>
        <div
          className="hamburger"
          onClick={() => setShowSidebar(!showSidebar)}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </header>

      <section className="support">
        <img className="features-logo" />
        {view !== "success" && (
          <h3 style={{ marginTop: 30 }}>Sign up Your Business</h3>
        )}
      </section>

      <section className="support-form">
        <div className="col-md-12 mt-4">
          {view === "store" ? (
            <div className="storeType-container">
              <div className="content">
                <h2>Select Store Type</h2>
                {storeTypes?.map((store) => (
                  <div
                    className="option"
                    onClick={() => setSelectedStoreType(store)}>
                    <label for="restaurant">{store}</label>
                    <input
                      checked={store === selectedStoreType}
                      type="radio"
                      name="store-type"
                      id={store}
                    />
                  </div>
                ))}
              </div>
              <div className="row flex form-buttons">
                <div className="col-md-6 button-container back-button">
                  <button
                    onClick={() => history.push("/")}
                    style={{
                      backgroundColor: "#ccc",
                      color: "#333333",
                      fontWeight: 500,
                    }}>
                    Back
                  </button>
                </div>
                <div className="col-md-6 button-container">
                  <button
                    className="btn primary"
                    onClick={storeNextHandler}>
                    Next
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="storeType-container">
              <div className="content">
                {view === "form" ? (
                  <h2>Add store and login details</h2>
                ) : (
                  <>
                    {view === "verification" && (
                      <p className="grey-text">
                        To continue, please enter the code sent to your email{" "}
                        <span style={{ color: "#333", fontWeight: 700 }}>
                          {getValues("email")}
                        </span>{" "}
                      </p>
                    )}
                  </>
                )}
              </div>

              {view === "form" ? (
                <form onSubmit={handleSubmit(registerHandler)}>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Store Name"
                      {...register("name")}
                    />
                    {errors.name && (
                      <div
                        style={{
                          color: "red",
                          fontSize: "12px",
                          textAlign: "left",
                          marginTop: "4px",
                        }}>
                        {errors.name.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Store Location"
                      onChange={(e) => {
                        setValue(e.target.value);
                        setAddress({ ...address, location: e.target.value });
                      }}
                      value={address.location || ""}
                    />
                    {status === "OK" && (
                      <div
                        style={{
                          zIndex: 1000,
                          border: "2px solid lightgrey",
                          borderTop: "0px",
                          backgroundColor: "white",
                          width: "100%",
                        }}>
                        {renderSuggestions()}
                      </div>
                    )}
                    {errors.location && (
                      <div
                        style={{
                          color: "red",
                          fontSize: "12px",
                          textAlign: "left",
                          marginTop: "4px",
                        }}>
                        {errors.location.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      placeholder="Enter Email"
                      {...register("email")}
                    />
                    {errors.email && (
                      <div
                        style={{
                          color: "red",
                          fontSize: "12px",
                          textAlign: "left",
                          marginTop: "4px",
                        }}>
                        {errors.email.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group password-field">
                    <input
                      type={passwordType}
                      placeholder="Enter Password"
                      {...register("password")}
                    />
                    {errors.password && (
                      <div
                        style={{
                          color: "red",
                          fontSize: "12px",
                          textAlign: "left",
                          marginTop: "4px",
                        }}>
                        {errors.password.message}
                      </div>
                    )}
                    {!errors?.password && (
                      <button
                        type="button"
                        onClick={() =>
                          setPasswordType(
                            passwordType === "password" ? "text" : "password",
                          )
                        }
                        className="show-password">
                        {passwordType === "password" ? "Show" : "Hide"}
                      </button>
                    )}
                  </div>
                  <div className="row flex form-buttons">
                    <div className="col-md-6 button-container back-button">
                      <button
                        onClick={() => {
                          setLoading(false);
                          setView("store");
                        }}
                        style={{
                          backgroundColor: "#ccc",
                          color: "#333333",
                          fontWeight: 500,
                        }}>
                        Back
                      </button>
                    </div>
                    <div className="col-md-6 button-container mb-2">
                      <button
                        role="submit"
                        disabled={isLoading}
                        className="btn primary">
                        {isLoading ? "Processing" : "Create Account"}
                      </button>
                    </div>
                  </div>
                </form>
              ) : view === "success" ? (
                <div>
                  <img
                    src={checkImg}
                    style={{ height: 80 }}
                  />

                  <h2
                    className="mt-3"
                    style={{ fontSize: 18 }}>
                    Thank You for Registration
                  </h2>

                  <p className="grey-text">
                    Congratulations your account has been successfully created.
                  </p>
                  <p className="grey-text mt-4">
                    Your account is under verification. We will inform you once
                    approved.
                  </p>
                  <div className="button-container">
                    <button onClick={() => history.push("/")}>Close</button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Verification Code"
                      onChange={(e) => setCode(e.target.value)}
                      value={code}
                    />
                    <div className="row flex form-buttons mt-4">
                      <div className="col-md-6 button-container back-button">
                        <button
                          onClick={() => {
                            setLoading(false);
                            setView("form");
                          }}
                          style={{
                            backgroundColor: "#ccc",
                            color: "#333333",
                            fontWeight: 500,
                          }}>
                          Back
                        </button>
                      </div>
                      <div className="col-md-6 button-container mb-2">
                        <button
                          onClick={sendCodeHandler}
                          disabled={isLoading}
                          className="btn primary">
                          {isLoading ? "Processing" : "Next"}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>

        <p style={{ color: "red", fontSize: "14px", textAlign: "center" }}>
          {msg ? msg : ""}
        </p>

        {(view === "store" || view === "form") && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p
              className="grey-text mt-4"
              style={{
                fontSize: 14,
                maxWidth: 400,
              }}>
              By creating an account, I agree to Wast Not Want Not's Privacy
              Policy.
              <a
                target="_blank"
                href="https://wastenwantn.com/privacy-policy/">
                {" "}
                View Privacy Page
              </a>
            </p>
          </div>
        )}
      </section>

      <footer className="footer">
        <div className="footer-logo">
          {/* <img
            src={logoImg}
            alt="Footer Logo"
          />
          <p>Empower everyone to fight food waste together.</p> */}
        </div>
        <p>Waste Not Want Not &copy; 2024. All rights reserved.</p>
        <div className="social-links">
          <a href="#">
            {/* <img
              src={icon}
              alt="Google Icon"
            /> */}
          </a>
          <a href="#">
            {/* <img
              src={icon1}
              alt="Twitter Icon"
            /> */}
          </a>
          <a href="#">
            {/* <img
              src={icon2}
              alt="Instagram Icon"
            /> */}
          </a>
          <a href="#">
            {/* <img
              src={icon3}
              alt="LinkedIn Icon"
            /> */}
          </a>
          {/* <p
            style={{ cursor: "pointer", fontWeight: 500, color: "#6abf4b" }}
            onClick={() => history.push("/wnwn/support")}>
            Support
          </p> */}
        </div>
      </footer>
    </div>
  );
};

export default SingupForm;
