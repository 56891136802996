import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/support-style.css";
import logoImg from "../../assets/home-img/logo.png";
import HomeSidebar from "./home-sidebar";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object({
  fName: yup.string().trim().required("First name is Required"),
  lName: yup.string().trim().required("Last name is Required"),
  email: yup.string().trim().required("Email is Required"),
  phone: yup.string().trim(),
  message: yup.string().trim().required("Message is Required"),
});

const Support = ({ history }) => {
  const [msg, setMsg] = useState("");
  const [text, setText] = useState("");
  const [showSidebar, setShowSidebar] = useState(false);
  const [view, setView] = useState("form");

  useEffect(() => {
    setShowSidebar(false);
  }, [window.location.href]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const sendHandler = () => {
    setView("message");
  };

  return (
    <>
      <HomeSidebar
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
        history={history}
      />
      <header className="header">
        <div className="logo">
          <a role="button">
            <img
              src={logoImg}
              alt="Waste not Want not Logo"
            />
          </a>
        </div>
        <nav className="nav">
          <ul>
            <li>
              <a
                role="button"
                onClick={() => {
                  history.push("/");
                }}>
                Home
              </a>
            </li>
            <li>
              <a
                role="button"
                onClick={() => {
                  history.push("/#about-us");
                }}>
                About us
              </a>
            </li>
            <li>
              <a
                role="button"
                onClick={() => {
                  history.push("/wnwn/support");
                }}>
                Support
              </a>
            </li>
            <li>
              <a
                role="button"
                onClick={() => history.push("/wnwn/signup")}
                className="btn">
                Sign Up Your Business
              </a>
            </li>
            {/* <li>
              <a href="#">Help</a>
            </li> */}
          </ul>
        </nav>
        <div
          className="hamburger"
          onClick={() => setShowSidebar(!showSidebar)}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </header>
      <div className="container-support-page">
        <div className="left-section">
          <h1>Contact Support</h1>
          <div className="contact-method">
            <h2>Call us (Mon-Fri 9-5:30)</h2>
            <p>
              <a href="tel:6306643921">(630) 664 3921 →</a>
            </p>
          </div>
          <div className="contact-method">
            <h2>Email us</h2>
            <p>
              <a href="mailto:info@wastenwantn.com">info@wastenwantn.com →</a>
            </p>
          </div>
        </div>
        <div className="right-section">
          {view === "form" ? (
            <div className="form-card">
              <h2>Experiencing an issue? We can help!</h2>
              <p>
                Our friendly support team is on hand to help you resolve any
                issues you may be experiencing with your system.
              </p>
              <form
                onSubmit={handleSubmit(sendHandler)}
                className="contact-form">
                <div className="row">
                  <div className="form-group-support col-md-6">
                    <label for="first-name">First name *</label>
                    <input
                      type="text"
                      id="first-name"
                      name="first-name"
                      {...register("fName")}
                    />
                    {errors.fName && (
                      <div
                        style={{
                          color: "red",
                          fontSize: "12px",
                          textAlign: "left",
                          marginTop: "4px",
                        }}>
                        {errors.fName.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group-support col-md-6">
                    <label for="last-name">Last name *</label>
                    <input
                      type="text"
                      id="last-name"
                      name="last-name"
                      {...register("lName")}
                    />
                    {errors.lName && (
                      <div
                        style={{
                          color: "red",
                          fontSize: "12px",
                          textAlign: "left",
                          marginTop: "4px",
                        }}>
                        {errors.lName.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group-support col-md-6">
                    <label for="email">Email *</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      {...register("email")}
                    />
                    {errors.email && (
                      <div
                        style={{
                          color: "red",
                          fontSize: "12px",
                          textAlign: "left",
                          marginTop: "4px",
                        }}>
                        {errors.email.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group-support col-md-6">
                    <label for="phone-number">Phone number</label>
                    <input
                      type="tel"
                      id="phone-number"
                      {...register("phone")}
                    />
                  </div>
                </div>

                <div className="form-group-support">
                  <label for="message">Message*</label>
                  <textarea
                    id="message"
                    name="message"
                    {...register("message")}
                    rows="4"></textarea>
                  {errors.message && (
                    <div
                      style={{
                        color: "red",
                        fontSize: "12px",
                        textAlign: "left",
                        marginTop: "4px",
                      }}>
                      {errors.message.message}
                    </div>
                  )}
                </div>
                <p className="privacy-notice">
                  We will use your information to communicate any future events
                  and services. You may unsubscribe at any time. For further
                  details please review our privacy policy
                </p>
                <button
                  type="submit"
                  className="submit-btn">
                  Submit →
                </button>
              </form>
            </div>
          ) : (
            <div
              className="form-card"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <p
                className="grey-text"
                style={{ textAlign: "center" }}>
                Thank you for contacting us, we will get back to you as soon as
                possible.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Support;
