import React from "react";

const HomeSidebar = ({
  showSidebar,
  setShowSidebar,
  heroRef,
  featuresRef,
  scrollToSection,
  history,
  setView,
}) => {
  return (
    <div>
      <aside
        id="layout-menu-admin"
        className={`layout-menu menu-vertical menu bg-menu-theme ${
          showSidebar ? "show-sidebar" : ""
        } `}>
        <div className="sidebar-header"></div>
        <div
          className="py-lg-3 mb-3 px-2"
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            marginTop: -80,
          }}>
          <img
            src="assets/img/WnWn-logo-transparent.png"
            width="80"
          />
          {/* <h6 style={{ color: "#333" }}>Waste Not  Want Not</h6> */}
        </div>

        <div id="menu-scroll">
          <div className="menu-block">
            <ul className="menu-inner">
              <li className={`menu-item `}>
                <a
                  className="menu-link menu-link-admin"
                  role="button"
                  onClick={() => {
                    if (scrollToSection) {
                      scrollToSection(heroRef);
                    } else {
                      history.push("/");
                    }
                    if (setView) setView("home");
                    setShowSidebar(false);
                  }}>
                  Home
                </a>
              </li>
              <li className={`menu-item `}>
                <a
                  className="menu-link menu-link-admin"
                  role="button"
                  onClick={() => {
                    if (scrollToSection) {
                      scrollToSection(featuresRef);
                    } else {
                      history.push("/#about-us");
                    }
                    setShowSidebar(false);
                  }}>
                  About us
                </a>
              </li>
              <li
                className={`menu-item`}
                role="button"
                onClick={() => {
                  history.push("/wnwn/support");
                  setShowSidebar(false);
                }}>
                <a className="menu-link menu-link-admin">Support</a>
              </li>
              <li className={`menu-item `}>
                <a
                  role="button"
                  onClick={() => {
                    history.push("/wnwn/signup");
                    setShowSidebar(false);
                  }}
                  className="btn menu-link menu-link-admin">
                  Sign Up Your Business
                </a>
              </li>
            </ul>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default HomeSidebar;
