import React from "react";

const TextField = ({
  divClass = "style-line mb-3",
  inputType = "text",
  inputClass = "form-control",
  placeholder = "",
  register,
  fieldName,
  errors,
  viewPassword,
  icon,
  disabled = false,
}) => {
  return (
    <>
      {icon ? (
        <div className={divClass}>
          <div className="input-group">
            <input
              type={inputType}
              className="form-control input-group-form"
              placeholder={placeholder}
              {...register(`${fieldName}`)}
            />
            <span
              className="input-group-text cursor-pointer border-0"
              onClick={
                (placeholder === "Password" ||
                  placeholder === "Enter Password" ||
                  placeholder === "Confirm Password") &&
                viewPassword
              }>
              {icon}
            </span>
            <span className="line"></span>
          </div>
        </div>
      ) : (
        <div className={divClass}>
          {fieldName === "description" ? (
            <textarea
              type={inputType}
              className={inputClass}
              placeholder={placeholder}
              {...register(`${fieldName}`)}
              disabled={disabled}
            />
          ) : (
            <input
              type={inputType}
              className={inputClass}
              placeholder={placeholder}
              {...register(`${fieldName}`)}
              disabled={disabled}
            />
          )}
          <span className="line"></span>
        </div>
      )}
      <p style={{ color: "red", fontSize: "12px", marginTop: "-14px" }}>
        {errors?.[fieldName]?.message}
      </p>
    </>
  );
};

export default TextField;
